import Button from 'components/button/Button';
import {useModal} from 'context/ModalContext';
import React from 'react';

const ResetStep2 = () => {
  const {openModal, MODAL_TYPES} = useModal();

  const handleLogin = () => {
    openModal(MODAL_TYPES.LOGIN);
  };

  return (
    <div className='flex w-full flex-col items-center px-25 pb-26 pt-40'>
      <div className='flex w-full flex-col items-center'>
        <img src='/images/illusts/il_check.png' alt='reset' className='h-[54px] w-[54px] object-cover' />
        <h1 className='mt-3 text-32bold'>Reset Password</h1>
        <p className='mt-2.5 text-center text-18medium text-gray-sub'>
          Your new password has been successfully set.
          <br />
          Click the button below to log in.
        </p>
      </div>
      <Button text='Log in' shape='SQUARE' className='mt-32' onClick={handleLogin} />
    </div>
  );
};

export default ResetStep2;
