import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as DownArrow} from 'assets/icons/ic_triangle.svg';

interface DropdownContainerProps {
  label?: string;
  placeholder?: string;
  error?: string;
  options: string[];
  value: string;
  setValue: (value: string) => void;
  className?: string;
}

const DropdownContainer = ({
  label,
  placeholder = 'Select',
  error,
  options,
  value,
  setValue,
  className = 'w-full',
}: DropdownContainerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`relative flex flex-col gap-y-0.5 ${className}`}>
      <div className='flex w-full flex-col gap-y-2'>
        {label !== undefined && <span className='text-13regular'>{label}</span>}
        <div ref={dropdownRef} className={`relative w-full`}>
          <div
            className={`flex h-11 w-full cursor-pointer items-center gap-x-1 rounded bg-gray-0 pl-[17px] pr-[11px] ${error ? 'border border-red' : ''}`}
            onClick={handleToggleOpen}
          >
            <span className={`flex-1 ${value === '' ? 'text-gray-70' : ''}`}>{value === '' ? placeholder : value}</span>
            <DownArrow className={`${isOpen ? 'rotate-180' : ''} transform text-[#ACACAC] duration-150`} />
          </div>
          {isOpen && (
            <div className='absolute z-10 w-full pb-5 pt-1'>
              <ul className='flex max-h-48 w-full flex-col gap-y-2.5 overflow-y-auto rounded-lg bg-white p-2 shadow-md'>
                {options.map(option => (
                  <li
                    key={option}
                    className='text-gray-[#6B6B6B] w-full cursor-pointer px-6 py-4 text-16medium hover:bg-gray-0'
                    onClick={() => {
                      setValue(option);
                      setIsOpen(false);
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <span className='h-4 text-[11px] text-red'>{error}</span>
    </div>
  );
};

export default DropdownContainer;
