import {usePatchPassword} from 'apis/handler/auth/usePatchPassword';
import Button from 'components/button/Button';
import InputContainer from 'components/input/InputContainer';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {isValidPassword, removeWhitespace} from 'utils/utility';

interface ResetStep1Props {
  onClickNext: () => void;
}

const ResetStep1 = ({onClickNext}: ResetStep1Props) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';

  const {mutateAsync: tryChangePassword, isPending} = usePatchPassword({
    onSuccess: data => {
      console.log(data);
      onClickNext();
    },
    onError: error => {
      alert(error);
    },
  });

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeWhitespace(e.target.value);
    setPasswordError('');
    setPassword(value);
  };

  const onChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeWhitespace(e.target.value);
    setConfirmPasswordError('');
    setConfirmPassword(value);

    if (value !== '' && password !== value) {
      setConfirmPasswordError('Password does not match');
    }
  };

  const handleNext = async () => {
    if (isPending) return;

    if (token === '') {
      alert('Token is required');
      return;
    }

    if (password === '' || !isValidPassword(password)) {
      setPasswordError('Please check the password creation requirements.');
      return;
    }

    if (confirmPassword === '' || confirmPassword !== password) {
      setConfirmPasswordError('Does not match with the New password.');
      return;
    }

    try {
      await tryChangePassword({password, passwordConfirm: confirmPassword, resetPasswordToken: token});
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className='flex w-full flex-col px-20 pb-16 pt-14'>
      <ContentModalHeader src='/images/illusts/il_lock.png' title='Reset Password' />
      <p className='w-full whitespace-pre-wrap break-words text-center text-18medium text-gray-sub'>
        Create a new password.{' '}
        <span className='text-error'>
          The password must be at least 8 characters long and include lowercase letters, uppercase letters, and numbers.
        </span>
      </p>
      <div className='mt-9 flex w-full flex-col px-28'>
        <div className='flex w-full flex-col gap-y-px'>
          <InputContainer
            label='New password'
            placeholder='Enter your password'
            type='password'
            error={passwordError}
            value={password}
            onChange={onChangePassword}
          />
          <InputContainer
            label='Confirm password'
            placeholder='Confirm your password'
            type='password'
            error={confirmPasswordError}
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
          />
        </div>
        <Button text='Update password' shape='SQUARE' className='mt-25' onClick={handleNext} disabled={isPending} />
      </div>
    </div>
  );
};

export default ResetStep1;
