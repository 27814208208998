import React from 'react';

interface InputContainerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  className?: string;
}

const InputContainer = ({label, error, className = 'w-full', ...props}: InputContainerProps) => {
  return (
    <div className={`flex flex-col gap-y-0.5 ${className}`}>
      <div className='flex w-full flex-col gap-y-2'>
        {label !== undefined && <span className='text-13regular'>{label}</span>}
        <input
          {...props}
          className={`h-11 w-full rounded border border-gray-0 bg-gray-0 px-[17px] py-[7px] outline-none placeholder:text-gray-70 focus:outline-none focus:ring-1 focus:ring-black disabled:text-gray-70 ${error !== undefined && error !== '' ? 'border-red' : ''}`}
        />
      </div>
      <span className='h-4 text-[11px] text-red'>{error}</span>
    </div>
  );
};

export default InputContainer;
