import {usePostFindEmail} from 'apis/handler/auth/usePostFindEmail';
import FindEmailStep1 from 'components/login/findEmail/FindEmailStep1';
import FindEmailStep2 from 'components/login/findEmail/FindEmailStep2';
import ContentModal from 'components/modal/ContentModal';
import React, {useState} from 'react';
import {removeWhitespace} from 'utils/utility';

interface FindEmailModalProps {
  onClose: () => void;
}

const FindEmailModal = ({onClose}: FindEmailModalProps) => {
  const [step, setStep] = useState(1);

  const {mutateAsync: tryFindEmail, data} = usePostFindEmail({
    onSuccess: data => {
      console.log(data);
      setStep(2);
    },
    onError: error => {
      alert(error);
    },
  });

  const onClickFindEmail = async (form: {
    firstName: string;
    lastName: string;
    birth: string;
    countryCode: string;
    phoneNumber: string;
  }) => {
    console.log('tryFindEmail : ', form);

    try {
      await tryFindEmail({
        body: {
          firstName: form.firstName,
          lastName: form.lastName,
          birth: form.birth,
          phoneNumber: removeWhitespace(form.countryCode, 'NUM') + removeWhitespace(form.phoneNumber, 'NUM'),
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <FindEmailStep1 tryFindEmail={onClickFindEmail} />;
      case 2:
        return <FindEmailStep2 data={data} />;
      default:
        return null;
    }
  };

  return <ContentModal onClose={onClose}>{renderStep()}</ContentModal>;
};

export default FindEmailModal;
