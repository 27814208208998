import {occupationType, visaType} from 'apis/handler/auth/authSchema';
import {furnitureType, roomType, whereType} from 'apis/handler/listing/listingSchema';

/**
 *
 * @param occupation 유저가 보는 occupation
 * @returns 서버에 보내는 occupation
 */
export const convertOccupationToRequest = (occupation: string): occupationType => {
  switch (occupation) {
    case 'Undergraduate Student':
      return 'UNDERGRADUATE';
    case 'Graduate Student':
      return 'GRADUATE';
    case 'Exchange Student':
      return 'EXCHANGE';
    case 'Education/Academic Research':
      return 'EDUCATION';
    case 'IT/Software/Engineering':
      return 'IT_SOFTWARE_ENGINEERING';
    case 'Manufacture/Agriculture':
      return 'MANUFACTURE_AGRICULTURE';
    case 'Arts/Design':
      return 'ARTS_DESIGN';
    case 'Finance/Banking':
      return 'FINANCE_BANKING';
    case 'Public Service/Government':
      return 'PUBLIC_SERVICE';
    case 'Healthcare/Medical Service':
      return 'HEALTHCARE_MEDICAL';
    case 'Business/Sales':
      return 'BUSINESS_SALES';
    case 'Legal/Consulting':
      return 'LEGAL_CONSULTING';
    case 'Others':
      return 'OTHERS';
    default:
      return 'OTHERS';
  }
};

/**
 *
 * @param occupation 서버에서 받는 occupation
 * @returns 유저가 보는 occupation
 */
export const convertRequestToOccupation = (occupationType: string): string => {
  switch (occupationType) {
    case 'UNDERGRADUATE':
      return 'Undergraduate Student';
    case 'GRADUATE':
      return 'Graduate Student';
    case 'EXCHANGE':
      return 'Exchange Student';
    case 'EDUCATION':
      return 'Education/Academic Research';
    case 'IT_SOFTWARE_ENGINEERING':
      return 'IT/Software/Engineering';
    case 'MANUFACTURE_AGRICULTURE':
      return 'Manufacture/Agriculture';
    case 'ARTS_DESIGN':
      return 'Arts/Design';
    case 'FINANCE_BANKING':
      return 'Finance/Banking';
    case 'PUBLIC_SERVICE':
      return 'Public Service/Government';
    case 'HEALTHCARE_MEDICAL':
      return 'Healthcare/Medical Service';
    case 'BUSINESS_SALES':
      return 'Business/Sales';
    case 'LEGAL_CONSULTING':
      return 'Legal/Consulting';
    case 'OTHERS':
      return 'Others';
    default:
      return 'null';
  }
};

/**
 *
 * @param visaInfo 유저가 보는 visaInfo
 * @returns 서버에 보내는 visaInfo
 */
export const convertVisaInfoToRequest = (visaInfo: string): visaType => {
  switch (visaInfo) {
    case 'Diplomatic/Official(A-1,A-2)':
      return 'DIPLOMATIC_OFFICIAL';
    case 'Visa Exempted(B)':
      return 'VISA_EXEMPTED';
    case 'Journalism/Religious Affairs(C-1, D-5, D-6)':
      return 'JOURNALISM';
    case 'Short Term Visit(C-2, C-3)':
      return 'SHORT_TEM_VISIT';
    case 'Study(D-2)':
      return 'STUDY';
    case 'Trainee(D-3,D-4)':
      return 'TRAINEE';
    case 'Intra-Company Transfer(D-7)':
      return 'INTRA_COMPANY_TRANSFER';
    case 'Professional(C-4, D-1, D-8, D-9, D-10, E-1, E-2, E-3, E-4, E-5, E-6, E-7)':
      return 'PROFESSIONAL';
    case 'Non-Professional(E-8, E-9, E-10)':
      return 'NON_PROFESSIONAL';
    case 'Working Holiday(H-1)':
      return 'WORKING_HOLIDAY';
    case 'Work and Visit(H-2)':
      return 'WORK_VISIT';
    case 'Familiy Visitor/Dependent Family(F-1, F-2, F-3)':
      return 'FAMILY_VISITOR';
    case 'Overseas Korean(F-4)':
      return 'OVERSEA_KOREAN';
    case 'Permanent Residence(F-5)':
      return 'PERMANENT_RESIDENCE';
    case 'Marrige Migrant(F-6)':
      return 'MARRIAGE_MIGRANT';
    case 'Others(G-1)':
      return 'OTHERS';
    default:
      return 'OTHERS';
  }
};

/**
 *
 * @param visaType 서버에서 받는 visaType
 * @returns 유저가 보는 visaType
 */
export const convertRequestToVisaInfo = (visaType: string): string => {
  switch (visaType) {
    case 'DIPLOMATIC_OFFICIAL':
      return 'Diplomatic/Official(A-1,A-2)';
    case 'VISA_EXEMPTED':
      return 'Visa Exempted(B)';
    case 'JOURNALISM':
      return 'Journalism/Religious Affairs(C-1, D-5, D-6)';
    case 'SHORT_TEM_VISIT':
      return 'Short Term Visit(C-2, C-3)';
    case 'STUDY':
      return 'Study(D-2)';
    case 'TRAINEE':
      return 'Trainee(D-3,D-4)';
    case 'INTRA_COMPANY_TRANSFER':
      return 'Intra-Company Transfer(D-7)';
    case 'PROFESSIONAL':
      return 'Professional(C-4, D-1, D-8, D-9, D-10, E-1, E-2, E-3, E-4, E-5, E-6, E-7)';
    case 'NON_PROFESSIONAL':
      return 'Non-Professional(E-8, E-9, E-10)';
    case 'WORKING_HOLIDAY':
      return 'Working Holiday(H-1)';
    case 'WORK_VISIT':
      return 'Work and Visit(H-2)';
    case 'FAMILY_VISITOR':
      return 'Familiy Visitor/Dependent Family(F-1, F-2, F-3)';
    case 'OVERSEA_KOREAN':
      return 'Overseas Korean(F-4)';
    case 'PERMANENT_RESIDENCE':
      return 'Permanent Residence(F-5)';
    case 'MARRIAGE_MIGRANT':
      return 'Marrige Migrant(F-6)';
    case 'OTHERS':
      return 'Others(G-1)';
    default:
      return 'null';
  }
};

/**
 *
 * @param whereType 유저가 보는 whereType
 * @returns 서버에 보내는 whereType
 */
export const convertWhereTypeToRequest = (whereType: string): whereType | undefined => {
  switch (whereType) {
    case 'University':
      return 'UNIVERSITY';
    case 'Address':
      return 'ADDRESS';
    case 'Station':
      return 'STATION';
    default:
      return undefined;
  }
};

/**
 *
 * @param whereType 서버에서 받는 whereType
 * @returns 유저가 보는 whereType
 */
export const convertRequestToWhereType = (whereType: string): string | undefined => {
  switch (whereType) {
    case 'UNIVERSITY':
      return 'University';
    case 'ADDRESS':
      return 'Address';
    case 'STATION':
      return 'Station';
    default:
      return undefined;
  }
};

export const convertRoomTypeToRequest = (roomType: string): roomType => {
  switch (roomType) {
    case 'Studio':
      return 'STUDIO';
    case 'Sharehouse':
      return 'SHAREHOUSE';
    case 'Officetel':
      return 'OFFICETEL';
    case 'Others':
      return 'OTHERS';
    default:
      return 'OTHERS';
  }
};

export const convertRequestToRoomType = (roomType: string): string => {
  switch (roomType) {
    case 'STUDIO':
      return 'Studio';
    case 'SHAREHOUSE':
      return 'Sharehouse';
    case 'OFFICETEL':
      return 'Officetel';
    case 'OTHERS':
      return 'Others';
    default:
      return 'null';
  }
};

export const convertRequestToFurnitureType = (furnitureType: furnitureType): string => {
  switch (furnitureType) {
    case 'GAS_STOVE':
      return 'Gas Stove';
    case 'INDUCTION':
      return 'Induction';
    case 'REFRIGERATOR':
      return 'Refrigerator';
    case 'WASHING_MACHINE':
      return 'Washing Machine';
    case 'MICROWAVE_OVEN':
      return 'Microwave Oven';
    case 'AIR_CONDITIONING':
      return 'Air Conditioning';
    case 'DRYER':
      return 'Dryer';
    case 'TABLE':
      return 'Table';
    case 'TV':
      return 'TV';
    case 'WIFI':
      return 'Wifi';
    case 'WARDROBE':
      return 'Wardrobe';
    case 'SHELF':
      return 'Shelf';
    case 'DRAWER':
      return 'Drawer';
    case 'SHOE_RACK':
      return 'Shoe Rack';
    case 'BED':
      return 'Bed';
    default:
      return 'null';
  }
};
