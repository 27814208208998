import React, {createContext, useCallback, useContext, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

export const MODAL_TYPES = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  PASSWORD: 'PASSWORD',
  RESET: 'RESET',
  FIND_EMAIL: 'FIND_EMAIL',
  NONE: 'NONE',
};

interface ModalContextType {
  modalType: string;
  openModal: (type: string) => void;
  closeModal: () => void;
  MODAL_TYPES: typeof MODAL_TYPES;
}

const defaultContextValue: ModalContextType = {
  modalType: MODAL_TYPES.NONE,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeModal: () => {},
  MODAL_TYPES,
};

const ModalContext = createContext<ModalContextType>(defaultContextValue);

export function ModalProvider({children}: {children: React.ReactNode}) {
  const [modalType, setModalType] = useState(MODAL_TYPES.NONE);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const openModal = useCallback(
    (type: string) => {
      if (searchParams.get('state')) {
        searchParams.delete('state');
        navigate(`?${searchParams.toString()}`, {replace: true});
      }
      setModalType(type);
    },
    [navigate, searchParams],
  );

  const closeModal = useCallback(() => {
    if (searchParams.get('state')) {
      searchParams.delete('state');
      navigate(`?${searchParams.toString()}`, {replace: true});
    }
    setModalType(MODAL_TYPES.NONE);
    window.location.reload();
  }, [navigate, searchParams]);

  return (
    <ModalContext.Provider value={{modalType, openModal, closeModal, MODAL_TYPES}}>{children}</ModalContext.Provider>
  );
}

export const useModal = () => {
  return useContext(ModalContext);
};
