import Button from 'components/button/Button';
import DropdownContainer from 'components/input/DropdownContainer';
import InputContainer from 'components/input/InputContainer';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {COUNTRY_CODE_OPTIONS, DAY_OPTIONS, MONTH_OPTIONS, YEAR_OPTIONS} from 'config/constant';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {removeWhitespace} from 'utils/utility';

interface FindEmailStep1Props {
  tryFindEmail: (form: {
    firstName: string;
    lastName: string;
    birth: string;
    countryCode: string;
    phoneNumber: string;
  }) => void;
}

const FindEmailStep1 = ({tryFindEmail}: FindEmailStep1Props) => {
  const [form, setForm] = useState<{
    firstName: string;
    lastName: string;
    birth: string;
    countryCode: string;
    phoneNumber: string;
  }>({
    firstName: '',
    lastName: '',
    birth: '',
    countryCode: '',
    phoneNumber: '',
  });
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [formError, setFormError] = useState<{
    firstName: string;
    lastName: string;
    birth: string;
    countryCode: string;
    phoneNumber: string;
  }>({
    firstName: '',
    lastName: '',
    birth: '',
    countryCode: '',
    phoneNumber: '',
  });

  const onChangeName = (field: string, value: string) => {
    setFormError(prevFormError => ({...prevFormError, [field]: ''}));
    setForm(prevForm => ({...prevForm, [field]: removeWhitespace(value, 'ENG')}));
  };

  const onChangePhoneNumber = (value: string) => {
    setFormError(prevFormError => ({...prevFormError, phoneNumber: ''}));
    setForm(prevForm => ({...prevForm, phoneNumber: removeWhitespace(value, 'NUM')}));
  };

  const findEmailHandler = () => {
    if (form.firstName === '') {
      setFormError(prevFormError => ({...prevFormError, firstName: 'Please enter your first name'}));
      return;
    }

    if (form.lastName === '') {
      setFormError(prevFormError => ({...prevFormError, lastName: 'Please enter your last name'}));
      return;
    }

    if (month === '' || day === '' || year === '') {
      setFormError(prevFormError => ({...prevFormError, birth: 'Please select Date of Birth'}));
      return;
    }
    const birth = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
    setForm(prevForm => ({...prevForm, birth}));

    if (form.countryCode === '') {
      setFormError(prevFormError => ({...prevFormError, countryCode: 'Please select Country Code'}));
      return;
    }

    if (form.phoneNumber === '') {
      setFormError(prevFormError => ({...prevFormError, phoneNumber: 'Please enter Phone Number'}));
      return;
    }

    tryFindEmail({...form, birth});
  };

  return (
    <div className='flex w-full flex-col px-25 pb-26 pt-14'>
      <ContentModalHeader
        src='/images/illusts/il_mail.png'
        title='Find Email'
        description='Please enter your info to find your email adress'
      />
      <div className='mt-2.5 flex w-full flex-col gap-y-px'>
        <div className='flex w-full items-center gap-x-6'>
          <InputContainer
            className='flex-1'
            label='First Name'
            placeholder='Enter your first name in English'
            value={form.firstName}
            onChange={e => onChangeName('firstName', e.target.value)}
            error={formError.firstName}
          />
          <InputContainer
            className='flex-1'
            label='Last Name'
            placeholder='Enter your last name in English'
            value={form.lastName}
            onChange={e => onChangeName('lastName', e.target.value)}
            error={formError.lastName}
          />
        </div>
        <div className='flex w-full flex-col gap-y-0.5'>
          <span className='text-13regular'>Date of Birth</span>
          <div className='flex w-full gap-x-6'>
            <DropdownContainer
              className='flex-1'
              placeholder='Month'
              value={month}
              setValue={value => {
                setFormError(prevFormError => ({...prevFormError, birthDay: ''}));
                setMonth(value);
              }}
              options={MONTH_OPTIONS}
              error=''
            />
            <DropdownContainer
              className='flex-1'
              placeholder='Day'
              value={day}
              setValue={value => {
                setFormError(prevFormError => ({...prevFormError, birthDay: ''}));
                setDay(value);
              }}
              options={DAY_OPTIONS}
              error=''
            />
            <DropdownContainer
              className='flex-1'
              placeholder='Year'
              value={year}
              setValue={value => {
                setFormError(prevFormError => ({...prevFormError, birthDay: ''}));
                setYear(value);
              }}
              options={YEAR_OPTIONS}
              error=''
            />
          </div>
          <span className='-mt-4 h-4 text-[11px] text-red'>{formError.birth}</span>
        </div>
        <div className='flex w-full gap-x-6'>
          <DropdownContainer
            className='flex-1'
            label='Country Code'
            value={form.countryCode}
            setValue={value => {
              setFormError(prevFormError => ({...prevFormError, countryCode: ''}));
              setForm(prevForm => ({...prevForm, countryCode: value}));
            }}
            options={COUNTRY_CODE_OPTIONS}
            error={formError.countryCode}
          />
          <InputContainer
            className='flex-1'
            placeholder='Enter numbers only'
            label='Phone Number'
            value={form.phoneNumber}
            onChange={e => onChangePhoneNumber(e.target.value)}
            error={formError.phoneNumber}
          />
        </div>
      </div>
      <Button text='Find Email' shape='SQUARE' onClick={findEmailHandler} className='mt-9' />
    </div>
  );
};

export default FindEmailStep1;
