import SignupStep1 from 'components/login/signup/SignupStep1';
import SignupStep2 from 'components/login/signup/SignupStep2';
import SignupStep3 from 'components/login/signup/SignupStep3';
import SignupStep4 from 'components/login/signup/SignupStep4';
import SignupStep5 from 'components/login/signup/SignupStep5';
import ContentModal from 'components/modal/ContentModal';
import React, {useState} from 'react';

interface SignupModalProps {
  onClose: () => void;
}

export type SignupForm = {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  birthday: string;
  countryCode: string;
  phoneNumber: string;
  nationality: string;
  occupation: string;
  visaInfo: string;
  password: string;
};

const SignupModal = ({onClose}: SignupModalProps) => {
  const [signupStep, setSignupStep] = useState(1);
  const [form, setForm] = useState<SignupForm>({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    birthday: '',
    countryCode: '',
    phoneNumber: '',
    nationality: '',
    occupation: '',
    visaInfo: '',
    password: '',
  });

  const handleInputChange = (field: string, value: string) => {
    setForm(prevForm => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const renderStep = () => {
    switch (signupStep) {
      case 1:
        return <SignupStep1 form={form} onInputChange={handleInputChange} onClickNext={() => setSignupStep(2)} />;
      case 2:
        return <SignupStep2 email={form.email} onClickNext={() => setSignupStep(3)} />;
      case 3:
        return <SignupStep3 form={form} onInputChange={handleInputChange} onClickNext={() => setSignupStep(4)} />;
      case 4:
        return <SignupStep4 form={form} onInputChange={handleInputChange} onClickNext={() => setSignupStep(5)} />;
      case 5:
        return <SignupStep5 onClose={onClose} />;
      default:
        return null;
    }
  };

  return <ContentModal onClose={onClose}>{renderStep()}</ContentModal>;
};

export default SignupModal;
