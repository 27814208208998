import React from 'react';

const PasswordStep2 = () => {
  return (
    <div className='flex w-full flex-col items-center px-56 pb-60 pt-44'>
      <img src='/images/illusts/il_mail.png' alt='password' className='h-[54px] w-[54px] object-cover' />
      <h1 className='mt-2.5 text-32bold leading-normal'>Reset Password</h1>
      <p className='mt-0.5 text-center text-18medium leading-normal text-gray-sub'>
        An email to reset your password has been
        <br />
        sent to your email address.
        <br />
        Please check your inbox.
      </p>
    </div>
  );
};

export default PasswordStep2;
