export const JWT_KEY = 'jwt';

export const FOOTER_INFO = {
  businessName: '(주)하우스버디',
  ceo: '임지은',
  personalInfoManager: '임지은',
  businessRegistrationNumber: '586-87-03123',
  onlineSaleRegistrationNumber: '2024-서울동작-0725',
  onlineSaleRegistrationNumberUrl: `http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5868703123`,
  address: '서울특별시 동작구 상도로55길8-3 숭실대학교 365스테이션 102호',
  termsOfUseUrl: `https://buttercup-coral-e9a.notion.site/9e7c367b66524429b1dee93c05d2929b?pvs=4`,
  privacyPolicyUrl: `https://buttercup-coral-e9a.notion.site/5db1eda2ab184b6c9f860103119b2d17?pvs=4`,
  customerSupport: `official@checkmatekorea.com`,
  customerTel: '010-8703-3395',
};

export const GENDER_OPTIONS = ['MALE', 'FEMALE'];

export const MONTH_OPTIONS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const DAY_OPTIONS = Array.from({length: 31}, (_, i) => (i + 1).toString());

export const YEAR_OPTIONS = Array.from({length: 100}, (_, i) => (2022 - i).toString());

export const COUNTRY_CODE_OPTIONS = [
  'Korea (+82)',
  'Japan (+81)',
  'China (+86)',
  'USA (+1)',
  'UK (+44)',
  'France (+33)',
  'Germany (+49)',
  'Russia (+7)',
  'Italy (+39)',
  'Spain (+34)',
  'Brazil (+55)',
  'Australia (+61)',
  'India (+91)',
  'Canada (+1)',
  'Mexico (+52)',
  'Indonesia (+62)',
  'Netherlands (+31)',
  'Saudi Arabia (+966)',
  'Turkey (+90)',
  'Switzerland (+41)',
  'Sweden (+46)',
  'Norway (+47)',
  'Denmark (+45)',
  'Finland (+358)',
  'Poland (+48)',
  'Czech Republic (+420)',
  'Hungary (+36)',
  'Romania (+40)',
  'Greece (+30)',
  'Portugal (+351)',
  'Austria (+43)',
  'Belgium (+32)',
  'Ukraine (+380)',
  'Kazakhstan (+7)',
  'Uzbekistan (+998)',
  'Iran (+98)',
  'Iraq (+964)',
  'Egypt (+20)',
  'South Africa (+27)',
  'Morocco (+212)',
  'Algeria (+213)',
  'Tunisia (+216)',
  'Libya (+218)',
  'Nigeria (+234)',
  'Ghana (+233)',
  'Kenya (+254)',
  'Ethiopia (+251)',
  'Tanzania (+255)',
  'Uganda (+256)',
  'Zambia (+260)',
  'Zimbabwe (+263)',
  'Botswana (+267)',
  'Namibia (+264)',
  'Mozambique (+258)',
  'Madagascar (+261)',
  'Angola (+244)',
  'Cameroon (+237)',
  'Ivory Coast (+225)',
  'Senegal (+221)',
  'Mali (+223)',
  'Guinea (+224)',
  'Sierra Leone (+232)',
  'Liberia (+231)',
  'Gambia (+220)',
  'Guinea-Bissau (+245)',
  'Cape Verde (+238)',
  'Mauritania (+222)',
  'Sao Tome and Principe (+239)',
  'Gabon (+241)',
  'Equatorial Guinea (+240)',
  'Congo (+242)',
  'Democratic Republic of the Congo (+243)',
  'Rwanda (+250)',
  'Burundi (+257)',
  'Seychelles (+248)',
  'Mauritius (+230)',
  'Comoros (+269)',
  'Mayotte (+262)',
  'Somalia (+252)',
];

export const NATIONALITY_OPTIONS = [
  'Korea',
  'Japan',
  'China',
  'USA',
  'UK',
  'France',
  'Germany',
  'Russia',
  'Italy',
  'Spain',
  'Brazil',
  'Australia',
  'India',
  'Canada',
  'Mexico',
  'Indonesia',
  'Netherlands',
  'Saudi Arabia',
  'Turkey',
  'Switzerland',
  'Sweden',
  'Norway',
  'Denmark',
  'Finland',
  'Poland',
  'Czech Republic',
  'Hungary',
  'Romania',
  'Greece',
  'Portugal',
  'Austria',
  'Belgium',
  'Ukraine',
  'Kazakhstan',
  'Uzbekistan',
  'Iran',
  'Iraq',
  'Egypt',
  'South Africa',
  'Morocco',
  'Algeria',
  'Tunisia',
  'Libya',
  'Nigeria',
  'Ghana',
  'Kenya',
  'Ethiopia',
  'Tanzania',
  'Uganda',
  'Zambia',
  'Zimbabwe',
  'Botswana',
  'Namibia',
  'Mozambique',
  'Madagascar',
  'Angola',
  'Cameroon',
  'Ivory Coast',
  'Senegal',
  'Mali',
  'Guinea',
  'Sierra Leone',
  'Liberia',
  'Gambia',
  'Guinea-Bissau',
  'Cape Verde',
  'Mauritania',
  'Sao Tome and Principe',
  'Gabon',
  'Equatorial Guinea',
  'Congo',
  'Democratic Republic of the Congo',
  'Rwanda',
  'Burundi',
  'Seychelles',
  'Mauritius',
  'Comoros',
  'Mayotte',
  'Somalia',
];

export const OCCUPATION_OPTIONS = [
  'Undergraduate Student',
  'Graduate Student',
  'Exchange Student',
  'Education/Academic Research',
  'IT/Software/Engineering',
  'Manufacture/Agriculture',
  'Arts/Design',
  'Finance/Banking',
  'Public Service/Government',
  'Healthcare/Medical Service',
  'Business/Sales',
  'Legal/Consulting',
  'Others',
];

export const VISA_INFO_OPTIONS = [
  'Diplomatic/Official(A-1,A-2)',
  'Visa Exempted(B)',
  'Journalism/Religious Affairs(C-1, D-5, D-6)',
  'Short Term Visit(C-2, C-3)',
  'Study(D-2)',
  'Trainee(D-3,D-4)',
  'Intra-Company Transfer(D-7)',
  'Professional(C-4, D-1, D-8, D-9, D-10, E-1, E-2, E-3, E-4, E-5, E-6, E-7)',
  'Non-Professional(E-8, E-9, E-10)',
  'Working Holiday(H-1)',
  'Work and Visit(H-2)',
  'Familiy Visitor/Dependent Family(F-1, F-2, F-3)',
  'Overseas Korean(F-4)',
  'Permanent Residence(F-5)',
  'Marrige Migrant(F-6)',
  'Others(G-1)',
];
