import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React, {PropsWithChildren} from 'react';

const queryClient = new QueryClient();

const BaseQueryClientProvider = (props: PropsWithChildren<unknown>) => {
  const {children} = props;

  queryClient.setDefaultOptions({
    mutations: {
      retry: false,
      /* eslint-disable @typescript-eslint/no-explicit-any */
      onError: (error: any) => {
        console.error(error);
      },
    },
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default BaseQueryClientProvider;
