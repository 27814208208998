import {create} from 'zustand';
import {persist} from 'zustand/middleware';

// TypeScript 인터페이스 정의
interface InfoState {
  firstName: string;
  lastName: string;
  email: string;
  setInfo: (info: {firstName: string; lastName: string; email: string}) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setEmail: (email: string) => void;
  resetInfo: () => void;
  getFirstName: () => string;
  getLastName: () => string;
  getEmail: () => string;
  getInfo: () => {firstName: string; lastName: string; email: string};
}

// Zustand Store 생성
export const useInfoStore = create(
  persist<InfoState>(
    (set, get) => ({
      firstName: '',
      lastName: '',
      email: '',

      setFirstName: firstName => set({firstName}),
      setLastName: lastName => set({lastName}),
      setEmail: email => set({email}),
      setInfo: ({firstName, lastName, email}) => set({firstName, lastName, email}),
      resetInfo: () =>
        set({
          firstName: '',
          lastName: '',
          email: '',
        }),

      getFirstName: () => get().firstName,
      getLastName: () => get().lastName,
      getEmail: () => get().email,
      getInfo: () => ({
        firstName: get().firstName,
        lastName: get().lastName,
        email: get().email,
      }),
    }),
    {
      name: 'info-store',
    },
  ),
);
