import {usePostResetEmail} from 'apis/handler/auth/usePostResetEmail';
import Button from 'components/button/Button';
import InputContainer from 'components/input/InputContainer';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {useModal} from 'context/ModalContext';
import React, {useState} from 'react';
import {removeWhitespace} from 'utils/utility';

interface PasswordStep1Props {
  onClickNext: () => void;
}

const PasswordStep1 = ({onClickNext}: PasswordStep1Props) => {
  const {openModal, MODAL_TYPES} = useModal();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const {mutate: trySendPasswordResetEmail, isPending} = usePostResetEmail({
    onSuccess: data => {
      console.log(data);
      onClickNext();
    },
    onError: error => {
      alert(error);
    },
  });

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(removeWhitespace(e.target.value));
  };

  const handleContinue = () => {
    if (isPending) return;

    if (email === '') {
      setEmailError('Please enter your email');
      return;
    }

    trySendPasswordResetEmail(email);
  };

  const handleFindEmail = () => {
    openModal(MODAL_TYPES.FIND_EMAIL);
  };

  return (
    <div className='flex w-full flex-col px-44 pb-12 pt-14'>
      <ContentModalHeader
        src='/images/illusts/il_lock.png'
        title='Reset Password'
        description={`Enter the email adress associcated with your account and we’ll send you a link to reset your password`}
      />
      <div className='flex w-full flex-col px-7'>
        <InputContainer
          label='Email Address'
          placeholder='Enter your email address'
          className='mt-14'
          value={email}
          onChange={onChangeEmail}
          error={emailError}
        />
        <Button text='Continue' shape='SQUARE' className='mt-20' onClick={handleContinue} disabled={isPending} />
        <p className='mt-16 text-center text-16medium'>
          {`Forgot your email? `}
          <span className='cursor-pointer text-primary underline-offset-2 hover:underline' onClick={handleFindEmail}>
            Find email
          </span>
        </p>
      </div>
    </div>
  );
};

export default PasswordStep1;
