import {useMutation} from '@tanstack/react-query';
import {mutationProps} from 'apis/core/commonSchema';
import {EmailFindReqDto, findEmailResponseSchema} from './authSchema';
import request from 'apis/core/Axios';

export const usePostFindEmail = ({onSuccess, onError}: mutationProps) => {
  return useMutation({
    mutationFn: async ({body}: {body: EmailFindReqDto}) => {
      const res = await request.post('/auth/member/find-email', {...body});
      return findEmailResponseSchema.parse(res);
    },
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      onError && onError(error);
    },
  });
};
