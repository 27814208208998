import Button from 'components/button/Button';
import React, {useState} from 'react';
import {ReactComponent as InfoIcon} from 'assets/icons/ic_info.svg';
import InputContainer from 'components/input/InputContainer';
import {isValidEmail, removeWhitespace} from 'utils/utility';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {usePostEmailCertification} from 'apis/handler/auth/usePostEmailCertification';

interface SignupStep1Props {
  form: {
    firstName: string;
    lastName: string;
    email: string;
  };
  onInputChange: (field: string, value: string) => void;
  onClickNext: () => void;
}

const SignupStep1 = ({onClickNext, form, onInputChange}: SignupStep1Props) => {
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const {mutateAsync: trySendEmailCertification, isPending} = usePostEmailCertification({
    onSuccess: data => {
      console.log(data);
      onClickNext();
    },
    onError: error => {
      alert(error);
    },
  });

  const handleNameChange = (field: string, value: string) => {
    setFirstNameError('');
    setLastNameError('');

    onInputChange(field, removeWhitespace(value, 'ENG'));
  };

  const handleEmailChange = (field: string, value: string) => {
    setEmailError('');

    onInputChange(field, removeWhitespace(value));
  };

  const handleNext = async () => {
    if (form.firstName === '') {
      setFirstNameError('Please enter your first name');
      return;
    }

    if (form.lastName === '') {
      setLastNameError('Please enter your last name');
      return;
    }

    if (form.email === '') {
      setEmailError('Please enter your email');
      return;
    }

    if (!isValidEmail(form.email)) {
      setEmailError('Please enter a valid email');
      return;
    }

    console.log('1 : ', form);

    try {
      await trySendEmailCertification(form.email);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex w-full flex-col px-24 pb-28 pt-14'>
      <ContentModalHeader
        src='/images/illusts/il_key.png'
        title='Sign up'
        description='Please enter your personal info'
      />
      <div className='mt-4 flex w-full flex-col gap-y-4'>
        <div className='flex w-full items-center gap-x-1 text-16medium text-error'>
          <InfoIcon />
          <p>Please enter your name in English as it appears on your passport.</p>
        </div>
        <div className='flex w-full flex-col gap-y-3'>
          <div className='flex w-full items-center gap-x-6'>
            <div className='flex-1'>
              <InputContainer
                label='First Name (EN)'
                placeholder='Enter your first Name in english'
                error={firstNameError}
                value={form.firstName}
                onChange={e => handleNameChange('firstName', e.target.value)}
              />
            </div>
            <div className='flex-1'>
              <InputContainer
                label='Last Name (EN)'
                placeholder='Enter your last name in english'
                error={lastNameError}
                value={form.lastName}
                onChange={e => handleNameChange('lastName', e.target.value)}
              />
            </div>
          </div>
          <InputContainer
            label='Email Address'
            placeholder='Enter your email address'
            error={emailError}
            value={form.email}
            onChange={e => handleEmailChange('email', e.target.value)}
          />
        </div>
      </div>
      <Button text='Continue' shape='SQUARE' onClick={handleNext} className='mt-12' disabled={isPending} />
    </div>
  );
};

export default SignupStep1;
