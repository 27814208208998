import Button from 'components/button/Button';
import React from 'react';
import {useNavigate} from 'react-router-dom';

interface SignupStep5Props {
  onClose: () => void;
}

const SignupStep5 = ({onClose}: SignupStep5Props) => {
  const navigate = useNavigate();

  const handleGoCheckBookingGuide = () => {
    console.log('5 : Go check booking guide');
    navigate('/guide');
    onClose();
  };

  return (
    <div className='flex w-full flex-col items-center justify-center px-24 pb-24 pt-40'>
      <div className='flex w-full flex-col items-center gap-y-4'>
        <img src='/images/illusts/il_check.png' alt='signup' className='h-[54px] w-[54px] object-cover' />
        <div className='flex w-full flex-col items-center gap-y-2'>
          <h1 className='text-32bold'>Welcome to Checkmate!</h1>
          <span className='w-full whitespace-normal break-words text-center text-18medium text-gray-sub'>{`Find out how to find rooms in Korea using\nCheckmate in the booking guide.`}</span>
        </div>
      </div>
      <Button text='Go check booking guide' shape='SQUARE' className='mt-32' onClick={handleGoCheckBookingGuide} />
    </div>
  );
};

export default SignupStep5;
