import {useMutation} from '@tanstack/react-query';
import request from 'apis/core/Axios';
import {mutationProps} from 'apis/core/commonSchema';
import {loginResponseSchema} from './authSchema';

export const usePostLogin = ({onSuccess, onError}: mutationProps) => {
  return useMutation({
    mutationFn: async ({email, password}: {email: string; password: string}) => {
      const res = await request.post('/auth/member/login', {email, password});
      return loginResponseSchema.parse(res);
    },
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      onError && onError(error);
    },
  });
};
