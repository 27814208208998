import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  shape?: 'ROUNDED' | 'SQUARE';
  theme?: 'PRIMARY' | 'BLACK' | 'GRAY' | 'WHITE';
  size?: 'TINY' | 'SMALL' | 'FULL';
  font?: string;
  children?: React.ReactNode;
}

const Button = ({
  text,
  shape,
  theme = 'PRIMARY',
  size = 'FULL',
  font = 'text-13regular leading-tight',
  className,
  children,
  ...props
}: ButtonProps) => {
  const rounded = shape === 'ROUNDED' ? 'rounded-full' : shape === 'SQUARE' ? 'rounded-md' : '';
  const color = () => {
    switch (theme) {
      case 'PRIMARY':
        return 'bg-primary text-white';
      case 'BLACK':
        return 'bg-black text-white';
      case 'GRAY':
        return 'bg-gray-200 text-black';
      case 'WHITE':
        return 'bg-white text-black';
      default:
        return '';
    }
  };

  const buttonSize = size === 'TINY' ? 'px-3 py-1.5' : size === 'SMALL' ? 'px-3 py-1.5' : 'py-2.5 w-full';

  return (
    <button className={`whitespace-pre ${rounded} ${color()} ${buttonSize} ${font} ${className}`} {...props}>
      {text}
      {children}
    </button>
  );
};

export default Button;
