import Button from 'components/button/Button';
import React, {useState} from 'react';
import InputContainer from 'components/input/InputContainer';
import {isValidPassword, removeWhitespace} from 'utils/utility';
import CheckboxInput from 'components/input/CheckboxInput';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {usePostSignup} from 'apis/handler/auth/usePostSignup';
import {SignupForm} from 'pages/login/SignupModal';
import {convertOccupationToRequest, convertVisaInfoToRequest} from 'utils/converter';
import {genderType} from 'apis/handler/auth/authSchema';

interface SignupStep4Props {
  form: SignupForm;
  onInputChange: (field: string, value: string) => void;
  onClickNext: () => void;
}

const SignupStep4 = ({onClickNext, form, onInputChange}: SignupStep4Props) => {
  const [checkPassword, setCheckPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordCheckError, setPasswordCheckError] = useState('');
  const [servicesAgree, setServicesAgree] = useState(false);
  const [privacyAgree, setPrivacyAgree] = useState(false);

  const {mutateAsync: trySignup, isPending} = usePostSignup({
    onSuccess: data => {
      console.log(data);
      onClickNext();
    },
    onError: error => {
      alert(error);
    },
  });

  const onChangePassword = (value: string) => {
    setPasswordError('');
    onInputChange('password', removeWhitespace(value));
  };

  const onChangeCheckPassword = (value: string) => {
    setPasswordCheckError('');
    setCheckPassword(removeWhitespace(value));
  };

  const handleNext = async () => {
    if (isPending) return;

    if (form.password === '' || !isValidPassword(form.password)) {
      setPasswordError('Please check the password creation requirements.');
      return;
    }

    if (checkPassword === '' || checkPassword !== form.password) {
      setPasswordCheckError('Does not match with the New password.');
      return;
    }

    if (!servicesAgree || !privacyAgree) {
      alert('You must agree to the required fields in order to register as a member.');
      return;
    }

    console.log('4 : ', form);

    try {
      await trySignup({
        body: {
          email: form.email,
          password: form.password,
          firstName: form.firstName,
          lastName: form.lastName,
          genderType: form.gender as genderType,
          birth: form.birthday,
          phoneNumber: form.phoneNumber,
          countryCode: removeWhitespace(form.countryCode, 'NUM'),
          nationality: form.nationality,
          occupationType: convertOccupationToRequest(form.occupation),
          visaType: convertVisaInfoToRequest(form.visaInfo),
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex w-full flex-col px-24 pb-10 pt-14'>
      <ContentModalHeader
        src='/images/illusts/il_key.png'
        title='Sign up'
        description='Create a new password. The password must be at least 8 characters long and include lowercase letters, uppercase letters, and numbers.'
      />
      <div className='mt-4 flex w-full flex-col gap-y-0.5'>
        <InputContainer
          type='password'
          label='Create password'
          placeholder='Enter your password'
          error={passwordError}
          value={form.password}
          onChange={e => onChangePassword(e.target.value)}
        />
        <InputContainer
          type='password'
          label='Confirm password'
          placeholder='Enter your password'
          error={passwordCheckError}
          value={checkPassword}
          onChange={e => onChangeCheckPassword(e.target.value)}
        />
      </div>
      <div className='mt-4 flex w-full flex-col gap-y-2'>
        <CheckboxInput checked={servicesAgree} setChecked={setServicesAgree}>
          I agree to{' '}
          <span
            className='underline underline-offset-2 hover:text-black'
            onClick={e => {
              e.stopPropagation();
              window.open('https://buttercup-coral-e9a.notion.site/9e7c367b66524429b1dee93c05d2929b?pvs=4', '_blank');
            }}
          >
            terms of services
          </span>
        </CheckboxInput>
        <CheckboxInput checked={privacyAgree} setChecked={setPrivacyAgree}>
          I agree to{' '}
          <span
            className='underline underline-offset-2 hover:text-black'
            onClick={e => {
              e.stopPropagation();
              window.open('https://buttercup-coral-e9a.notion.site/5db1eda2ab184b6c9f860103119b2d17?pvs=4', '_blank');
            }}
          >
            privacy policy
          </span>
        </CheckboxInput>
      </div>
      <Button text='Continue' shape='SQUARE' onClick={handleNext} className='mt-11' disabled={isPending} />
    </div>
  );
};

export default SignupStep4;
