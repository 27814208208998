import axios from 'axios';
import {JWT_KEY} from '../../config/constant';
import {clearJwt} from 'utils/utility';

export const request = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${window.localStorage.getItem(JWT_KEY)}`,
  },
});

request.interceptors.request.use(
  config => {
    const jwt = window.localStorage.getItem(JWT_KEY);
    config.headers.Authorization = `Bearer ${jwt}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

request.interceptors.response.use(
  response => {
    if (response.data.isSuccess) {
      return response.data.result;
    } else {
      return Promise.reject(response.data.message);
    }
  },
  error => {
    console.log(error);
    if (error.status === 403) {
      console.log('403 error');
      clearJwt();

      return Promise.reject('로그인이 필요합니다.');
    }
    return Promise.reject(error.code === 'ERR_NETWORK' ? '허용되지 않은 네트워크 접근입니다.' : error);
  },
);

export default request;
