import React from 'react';
import {ReactComponent as EmptyCheckbox} from 'assets/icons/ic_check_empty.svg';
import {ReactComponent as CheckedCheckbox} from 'assets/icons/ic_check_fill.svg';

interface CheckboxInputProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  children: React.ReactNode;
}

const CheckboxInput = ({checked, setChecked, children}: CheckboxInputProps) => {
  return (
    <div
      className='flex w-fit cursor-pointer items-center gap-x-2.5 text-[#6C6D6D]'
      onClick={() => setChecked(!checked)}
    >
      {checked ? <CheckedCheckbox width={16} height={16} /> : <EmptyCheckbox width={16} height={16} />}
      <div className='text-[10px] font-medium'>{children}</div>
    </div>
  );
};

export default CheckboxInput;
