import Button from 'components/button/Button';
import React, {useState} from 'react';
import InputContainer from 'components/input/InputContainer';
import {removeWhitespace} from 'utils/utility';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {usePostVerifyCode} from 'apis/handler/auth/usePostVerifyCode';
import {usePostEmailCertification} from 'apis/handler/auth/usePostEmailCertification';

interface SignupStep2Props {
  email: string;
  onClickNext: () => void;
}

const SignupStep2 = ({email, onClickNext}: SignupStep2Props) => {
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const {mutateAsync: tryVerifyCode, isPending: verifyCodeIsPending} = usePostVerifyCode({
    onSuccess: data => {
      console.log(data);
      onClickNext();
    },
    onError: error => {
      alert(error);
    },
  });

  const {mutateAsync: trySendEmailCertification, isPending: sendEmailIsPending} = usePostEmailCertification({
    onSuccess: data => {
      console.log(data);
    },
    onError: error => {
      alert(error);
    },
  });

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeError('');

    setCode(removeWhitespace(e.target.value, 'NUM'));
  };

  const handleResendEmail = async () => {
    if (sendEmailIsPending) return;
    try {
      await trySendEmailCertification(email);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = async () => {
    if (verifyCodeIsPending) return;

    if (code === '' || code.length < 6) {
      setCodeError('The code is incorrect');
      return;
    }

    try {
      await tryVerifyCode({email, code});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex w-full flex-col px-24 pb-28 pt-14'>
      <ContentModalHeader
        src='/images/illusts/il_mail.png'
        title='Sign up'
        description={`We sent verification code to ${email}.\nPlease check your inbox and enter code below.`}
      />
      <div className='mt-16 flex w-full flex-col items-end gap-y-2'>
        <InputContainer
          placeholder='Enter 6-digits code'
          error={codeError}
          value={code}
          onChange={onChangeCode}
          maxLength={6}
        />
        <p className='text-16medium'>
          {`Didn't receive the email?`}{' '}
          <span className='cursor-pointer text-primary underline-offset-2 hover:underline' onClick={handleResendEmail}>
            Resend email
          </span>
        </p>
      </div>
      <Button text='Continue' shape='SQUARE' onClick={handleNext} className='mt-24' disabled={verifyCodeIsPending} />
    </div>
  );
};

export default SignupStep2;
