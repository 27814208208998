import {findEmailResponseType} from 'apis/handler/auth/authSchema';
import Button from 'components/button/Button';
import InputContainer from 'components/input/InputContainer';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {useModal} from 'context/ModalContext';
import React from 'react';

interface FindEmailStep2Props {
  data?: findEmailResponseType;
}

const FindEmailStep2 = ({data}: FindEmailStep2Props) => {
  const {openModal, MODAL_TYPES} = useModal();

  const handleLogin = () => {
    openModal(MODAL_TYPES.LOGIN);
  };

  return (
    <div className='flex w-full flex-col px-52 pb-26 pt-14'>
      <ContentModalHeader src='/images/illusts/il_mail.png' title='Find Email' />
      <div className='mt-2.5 flex w-full flex-col gap-y-px'>
        {!data ? (
          <div>There is no account registered.</div>
        ) : (
          <>
            <InputContainer label='Name' defaultValue={`${data.firstName} ${data.lastName}`} disabled />
            <InputContainer label='Phone number' defaultValue={data.phoneNumber} disabled />
            <InputContainer label='Email' defaultValue={data.email} disabled />
          </>
        )}
      </div>
      <Button text='Login' shape='SQUARE' className='mt-5' onClick={handleLogin} />
    </div>
  );
};

export default FindEmailStep2;
